import React from "react";
import "./Scenario.css";
import { Link } from "react-router-dom";

function Scenario(props) {
  return (
    <div className="scenario-div mt-3 d-flex align-items-center justify-content-between">
      <div>
        <img src={"/images/" + props.img} className="scenario-icon" alt="icon"></img>
        <span className="scenario-span">{props.title} </span>
      </div>
      <Link to={"/practice/" + props.code}>
        <button type="button" className="btn btn-purple">
          Start Speaking
        </button>
      </Link>
    </div>
  );
}

export default Scenario;
