import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import TitleIcon from "../TitleIcon/TitleIcon";
import { faComment, faRectangleList, faFileLines } from "@fortawesome/free-regular-svg-icons";
// import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import "./Sidebar.css";

function Sidebar(props) {
  console.log(useLocation().pathname);
  console.log(useLocation().pathname === "/practice");

  return (
    <div className="nav flex-column nav-pills sidebar">
      <TitleIcon></TitleIcon>
      <li class="nav-item bg-white">
        <Link
          className={
            useLocation().pathname.startsWith("/dashboard")
              ? "text-purple nav-link active mt-4 py-3"
              : "text-purple nav-link mt-4 py-3"
          }
          to="/dashboard"
        >
          <h5>
            <FontAwesomeIcon icon={faRectangleList} />
            <span className="nav-span">Dashboard</span>
          </h5>
        </Link>
      </li>
      <li class="nav-item">
        <Link
          className={
            useLocation().pathname.startsWith("/practice")
              ? "text-purple nav-link active py-3"
              : "text-purple nav-link py-3"
          }
          to="/practice"
        >
          <h5>
            <FontAwesomeIcon icon={faComment} />
            <span className="nav-span"> Practice</span>
          </h5>
        </Link>
      </li>
      <li class="nav-item">
        <Link
          className={
            useLocation().pathname.startsWith("/review")
              ? "nav-link active py-3 text-purple"
              : "nav-link py-3 text-purple"
          }
          to="/review"
        >
          <h5>
            <FontAwesomeIcon icon={faBookOpen} />
            <span className="nav-span">Review</span>
          </h5>
        </Link>
      </li>
      <li class="nav-item">
        <Link
          className={
            useLocation().pathname === "/"
              ? "nav-link active py-3 text-purple"
              : "nav-link py-3 text-purple"
          }
          to="/"
        >
          <h5>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
            <span className="nav-span">Logout</span>
          </h5>
        </Link>
      </li>
    </div>
  );
}

export default Sidebar;
