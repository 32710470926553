import Sidebar from "../Sidebar/Sidebar";
import React, { useState } from 'react';

// function Dashboard() {
//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col-sm-2 p-0">
//           <Sidebar />
//         </div>
//       </div>
//     </div>
//   );
// }



// function Dashboard() {
//   const [recording, setRecording] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);

//   const startRecording = () => {
//     navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
//       const mediaRecorder = new MediaRecorder(stream);
//       const audioChunks = [];
//       mediaRecorder.addEventListener('dataavailable', (event) => {
//         audioChunks.push(event.data);
//         console.log("audioChunks", audioChunks);
//       });
//       mediaRecorder.addEventListener('stop', () => {
//         const audioBlob = new Blob(audioChunks);
//         setAudioBlob(audioBlob);
//         setRecording(false);
//       });
//       mediaRecorder.start();
//       setRecording(true);
//       setTimeout(() => mediaRecorder.stop(), 5000); // stop recording after 5 seconds
//     }).catch((error) => {
//       console.error('Error recording audio:', error);
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     if (!audioBlob) {
//       console.error('No audio recorded.');
//       return;
//     }
//     const formData = new FormData();
//     formData.append('audio', audioBlob);
//     fetch('/upload-audio', {
//       method: 'POST',
//       body: formData,
//     })
//       .then((response) => {
//         console.log('Audio uploaded successfully!');
//       })
//       .catch((error) => {
//         console.error('Error uploading audio:', error);
//       });
//   };

//   return (
//     <div className="container-fluid">
//     <div className="row">
//     <div className="col-sm-2 p-0">
//     <Sidebar />
//     </div>
//     <div className="col-sm chat-div p-4">
//     <div className="chat-window p-3">
//     <div>
//     {recording ? (
//       <p>Recording audio...</p>
//       ) : (
//         <button onClick={startRecording}>Record audio</button>
//         )}
//         <br />
//         {audioBlob && <audio src={URL.createObjectURL(audioBlob)} controls />}
//         <br />
//         <button onClick={handleSubmit} disabled={!audioBlob}>Upload audio</button>
//         </div>
//         </div>
//         </div>
//         </div>
//         </div>
//   );
// }


// export default Dashboard;


function Dashboard() {
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  // let mediaRecorder = null;
  const audioChunks = [];
  
  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {

        const recorder = new MediaRecorder(stream);
        recorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data);
          console.log('audioChunks!!!!!!', audioChunks);
        });

        recorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks);
          setAudioBlob(audioBlob);
          setRecording(false);
          stream.getTracks() // get all tracks from the MediaStream
            .forEach( track => track.stop() ); // stop each of them
        });
        recorder.start();
        setMediaRecorder(recorder);
        setRecording(true);
        // console.log('recording started', mediaRecorder);
      }).catch((error) => {
              console.error('Error recording audio:', error);
       });
  };
  
  const stopRecording = () => {
    console.log(mediaRecorder);
    mediaRecorder.stop();
    navigator.mediaDevices.getUserMedia({ audio: false });
    setMediaRecorder(null);
  };
  

  const sendAudio = async () => {
    const formData = new FormData();
    formData.append('audio', audioBlob);

    const response = await fetch('/api/audio', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      console.log('Audio sent successfully');
    } else {
      console.error('Failed to send audio');
    }
  };

  return (
    <div className="container-fluid">
    <div className="row">
    <div className="col-sm-2 p-0">
    <Sidebar />
    </div>
    <div className="col-sm chat-div p-4">
    <div className="chat-window p-3">
    <div>
      <button type="button" className="btn btn-purple" onClick={startRecording} disabled={recording}>
         Start Recording
      </button>

      <button type="button" className="btn btn-purple" onClick={stopRecording} disabled={!recording}>
         Stop Recording
      </button>

           <br />
         {audioBlob && <audio src={URL.createObjectURL(audioBlob)} controls />}
         <br />

      <button type="button" className="btn btn-purple" onClick={sendAudio} disabled={!audioBlob}>
        Send Audio
      </button>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default Dashboard;
