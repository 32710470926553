import { green, red } from "@mui/material/colors";
import React, { useEffect, useState } from "react";


import "./ProgressBar.css";

function ProgressBar(props) {

  const width = props.width * 10;
  let progressBarColor = red.A400;

  if (width >= 30 && width <= 70) {
    progressBarColor = green.A400;
  }
  else{
    progressBarColor = red.A400;
  }

  const progressBarStyle = {
    width: `${width}%`,
    backgroundColor: progressBarColor,
    height: '20px',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold'
  };

  return (
    <div>
      <h6>{props.title}</h6>
      <div className="progress-bar" role="progressbar" style={progressBarStyle} aria-valuenow={width} aria-valuemin="0" aria-valuemax="100">
        {`${width}%`}
      </div>
    </div>
  );
}

export default ProgressBar;