import React, { useState, useRef, useEffect } from "react";
import io from "socket.io-client";
import ScrollToBottom from "react-scroll-to-bottom";
import Speaker from "../Speaker/Speaker.tsx";
import Microphone from "../Microphone/Microphone.tsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {serverUrl, wsPathUrl} from "../utils/apiConfig";
import "./ChatRoom.css";
const ChatRoom = (props) => {
  const listenerType = "client";
  const scenario = props.code;
  const is_listener = listenerType === "listener1" || listenerType === "listener2"; // ? true ? listenerType === "client" : false : ""
  
  const [messages, setMessages] = useState([
    {
      is_listener: true,
      // utterance: "Hello, Is this Agoda?",
      utterance: "Hi, how are you doing?",
    },
  ]);
  const [message, setMessage] = useState("");


  const scenario_data = {
    starbucks: "You came to order a coffee at Starbucks.",
    library: "You came to order a coffee at Starbucks.",
    mcdonalds: "You came to order a burger at McDonalds.",
    costco: "You are came to return an item at Costco.",
    support: "You are Handling credit card decline",
  };

  const textbox = document.getElementById("chat__input-textbox");

  // const suggestions = ["nice message", "click this", "howdy"]
  let socketRef = useRef();
  const messageRef = useRef();

  useEffect(() => {
    socketRef.current = io.connect(`${serverUrl}`, {path:`/${wsPathUrl}/socket.io`});
    // socketRef.current = io.connect("https://app.flowyai.net/", {path:'/apiv2/socket.io'});
    console.log("socketRef connection Established")
    return () => {
      socketRef.current.disconnect();
      console.log("socketRef connection Disconnected")
    };
  }, []);

  useEffect(() => {
     socketRef.current.emit("update_scenario", scenario);
  }, [scenario]);

  useEffect(() => {
    console.log("useEffect running... in init");
    console.log(messages);
    if (message !== "") {
      socketRef.current.emit("add_message", is_listener, message);
      setMessage("");
    }

    socketRef.current.on("error", (args) => {
      alert("Received error from backend: " + args);
    });

    socketRef.current.on("new_message", (args) => {
      console.log("new message received");
      console.log(args);
      setMessages([
        ...messages,
        { is_listener: args["is_listener_gpt3"], utterance: args["utterance_gpt3"] },
      ]);
    });



    socketRef.current.on("intro_message", (args) => {
      setMessages([
        ...messages,
        { is_listener: args["is_listener"], utterance: args["utterance"] },
      ]);
    });


    props.setNumChats(messages.length);
    console.log("Message Length: " , messages.length);

  }, [messages]);

  const onChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    console.log("message sent");
    console.log(message);
    if (message !== "") {
      console.log([...messages, { is_listener: is_listener, utterance: message }]);

      // NEVER USE
      // setMessages([...messages, { is_listener: is_listener, utterance: message }]);
      // as it will not update the state immediately
      setMessages((oldMsg) => [...oldMsg, { is_listener: is_listener, utterance: message }]);

    } else {
      alert("Please add a message.");
    }
    textbox.focus();
  };


  const onStartAutoplay = () => {
    if (messages?.length > 0 && messages[messages?.length - 1].is_listener !== is_listener) {
      var spkr = document.getElementById(["chat__message-speaker-" + (messages?.length - 1)]);
      console.log(spkr);
      if (spkr != null) {
        spkr.click();
        console.log("starting autoplay");
      } else {
        console.log("could not find last message's speaker to autoplay");
      }
    }
  };

  return (
    <>
      <div className="chat">
        <header className="chat__header">
          <div className="chat__header-container">
            <div className="chat__header-item">
              <div className="chat__item-group">
                <h2>
                  <Link to="/practice" className="pe-4 text-purple">
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                  </Link>
                  {/* <div className="user-img-chat">{is_listener ? "M" : "L"}</div> */}
                  {!is_listener ? scenario_data[scenario] : "Member"}
                </h2>
              </div>
            </div>
          </div>
        </header>
        <section
          className="chat__body">
          <div className="chat__body-container">
            <ScrollToBottom className="chat__messages-list">
              {messages?.length > 0 &&
                messages.map((message, i) => (
                  <li key={i} ref={messageRef} className="chat__message-item">
                    {" "}
                    <div className="chat__message-container">
                      {message.is_listener !== is_listener ? (
                        <div className="user-img-chat">{message.is_listener ? i + 1 : "M"}</div>
                      ) : (
                        <span></span>
                      )}
                      <div
                        className={`
                      chat__message-message
                      ${message.is_listener === is_listener ? "my-message" : "received-message"}`}
                      >
                        {message.utterance}
                      </div>
                      <div>
                        <Speaker
                          getText={(args) => {
                            console.log(args);
                            return message.utterance;
                          }}
                          id={["chat__message-speaker-" + i]}
                        />
                      </div>
                    </div>
                  </li>
                ))}
            </ScrollToBottom>
          </div>
        </section>
        <section className="chat__input">
          <div className="chat__input-wrapper">
            <form onSubmit={onSendMessage}>
              <input
                id="chat__input-textbox"
                value={message}
                onChange={onChangeMessage}
                placeholder="Type your message here..."
                autocomplete="off"
                autoFocus
              />
              <Microphone
                setText={(args) => {
                  console.log(args);
                  setMessage(args);
                }}
                id="microphone"
              />
              <button className="submit__icon">
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  className="text-purple fa-lg"
                ></FontAwesomeIcon>
              </button>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChatRoom;
