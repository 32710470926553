import "./TitleIcon.css";
import { Link } from "react-router-dom";
function TitleIcon(props) {
  return (
    <Link to="/" className="title-link">
    <div className={`d-flex  ${props.className_css}`}>
      <img src={"/images/flowylogo.png"} className="scenario-icon" alt="icon"></img>
      <h1 style={{ color: "#A127FF" }}>FLOWY</h1>
    </div>
    </Link>
  );
}

export default TitleIcon;
