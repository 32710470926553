import "./Signin.css";
import TitleIcon from "../TitleIcon/TitleIcon";
import { Link } from "react-router-dom";
function Signin() {
  return (
    <div className="container-fluid p-0 m-0">
      <div className="row signin-row align-items-center">
        <div className="col-6 text-center p-5">
          <div className='d-flex justify-content-center' >
            <img className= 'title-icon' src={"/images/flowylogo.png"}  alt="icon"></img>
            <h1 className= 'title' style={{ color: "#A127FF" }}>FLOWY</h1>
          </div>
          <form>
            <h1 class="h3 mb-3">Please sign in</h1>

            <div className="form-floating my-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label for="floatingInput">Email address</label>
            </div>
            <div className="form-floating my-3">
              <input
                type="password"
                class="form-control"
                id="floatingPassword"
                placeholder="Password"
              />
              <label for="floatingPassword">Password</label>
            </div>

            <div className="checkbox mb-3">
              <label>
                <input type="checkbox" value="remember-me" /> Remember me
              </label>
            </div>
            <Link to="/practice">
              <button className="w-100 btn btn-lg btn-primary btn-purple" type="submit">
                Sign in
              </button>
            </Link>
          </form>
        </div>
        <div className="col-6 m-0 p-0">
          <div className="background-slide m-0"></div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
