import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { testScenarioData } from "../Practice/Practice";
import ProgressBar from "../ProgressBar/ProgressBar";
import { randomIntFromInterval } from "../utils/utils";

import "./CheckListAndDropdown.css";

function CheckListAndDropdown(props) {
  // State with list of all checked item
  console.log(props.code);
  console.log(useParams());
  const { code } = useParams();

  const [checked, setChecked] = useState([]);
  const [checkList, setCheckList] = useState([]);

  const [progressValues, setProgressValues] = useState({
    pace : 5,
    intonation : 2,
    fluency : 5,
    pronunciation : 5,
    filer_words: 5,
  })

  useEffect(() => {
    console.log("calling useEffect");
    // grab chat data on mount from chatId

    const idx = testScenarioData.findIndex((scenario) => scenario.code === code);
    console.log(idx);
    if (idx !== -1) {
      setCheckList(testScenarioData[idx].checkList);
      console.log(testScenarioData[idx].checkList);
    }
  }, [code]);

  useEffect(() => {
    // Update the document title using the browser API
    // grab chat data on mount from chatId
    console.log(props.numChats);
    setProgressValues({
        pace : randomIntFromInterval(2, 8),
        intonation : randomIntFromInterval(2, 8),
        fluency : randomIntFromInterval(2, 8),
        pronunciation : randomIntFromInterval(2, 8),
        filer_words: randomIntFromInterval(2, 8)
    })

  }, [props.numChats]);
  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

  return (
    <div className="app">
      <div className="checkList">
        <div className="title">Your CheckList:</div>
        <div className="list-container">
          {checkList.map((item, index) => (
            <div key={index}>
              <input value={item} type="checkbox" disabled="true"  checked={(index + 1 ) <= props.numChats/2} />
              <span className={isChecked(item)}>{item}</span>
            </div>
          ))}

         <div className="title1">Your Talking Fluency:</div>
         {Object.entries(progressValues).map(([key, value]) => (

              <ProgressBar title={key} width={value}/>
         ))}

        </div>
      </div>
    </div>
  );
}

export default CheckListAndDropdown;