import Sidebar from "../Sidebar/Sidebar";
import { useState, useRef } from "react";
import ChecklistAndDropdown from "../CheckListAndDropdown/CheckListAndDropdown";

function Review() {

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm chat-div p-4">
          <div className="chat-window p-3">
            <ChecklistAndDropdown />
          </div>
        </div>
      </div>
    </div>



  );
}

export default Review;
