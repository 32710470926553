import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Sidebar from "../Sidebar/Sidebar";
import ChatRoom from "../ChatRoom/ChatRoom";
import "./Chat.css";
import { testScenarioData } from "../Practice/Practice";
import CheckListAndDropdown from "../CheckListAndDropdown/CheckListAndDropdown";

function Chat() {
  console.log(useParams());
  const { code } = useParams();
  const [chatData, setChatData] = useState(null);
  const [numChats, setNumChats] = useState(0);

  useEffect(() => {
    console.log("calling useEffect");
    // grab chat data on mount from chatId

    const idx = testScenarioData.findIndex((scenario) => scenario.code === code);
    console.log(idx);
    if (idx !== -1) {
      setChatData(testScenarioData[idx]);
      console.log(testScenarioData[idx]);
    }
  }, [code]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm chat-div p-4">
          <div className="chat-window p-3">
            {chatData !== null ? (
              <div>
                <ChatRoom code={code} setNumChats={setNumChats}/>
              </div>
            ) : (
              <h2>
                <Link to="/practice">
                  <button>Back</button>
                </Link>{" "}
                Scenario not found
              </h2>
            )}
          </div>
        </div>
        <div className="col-sm-2 p-0">
          <CheckListAndDropdown numChats={numChats} code={code}/>
        </div>
      </div>
    </div>
  );
}

export default Chat;
