import "./App.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./SignIn/Signin";
import Practice from "./Practice/Practice";
import Dashboard from "./Dashboard/Dashboard";
import Review from "./Review/Review";
import Chat from "./Chat/Chat";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/practice" element={<Practice />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/review" element={<Review />} />
        <Route exact path="/practice/:code" element={<Chat />} />
        <Route exact path="/" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
