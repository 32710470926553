import axios from "axios";
import Cookie from "universal-cookie";
import {serverUrl, httpPathUrl} from "./apiConfig";

export async function getTokenOrRefresh() {
  const cookie = new Cookie();
  const speechToken = cookie.get("speech-token-1");

  if (speechToken === undefined) {
    try {
      const res =  await axios.get(`${serverUrl}/${httpPathUrl}/get-speech-token`);
      // const res = await axios.get("https://app.flowyai.net/apiv2/api/get-speech-token");
      const token = res.data.token;
      const region = res.data.region;
      cookie.set("speech-token", region + ":" + token, {
        maxAge: 540,
        path: "/",
      });

      //   console.log("Token fetched from back-end: " + token);
      return { authToken: token, region: region };
    } catch (err) {
      console.log(err.response.data);
      return { authToken: null, error: err.response.data };
    }
  } else {
    // console.log("Token fetched from cookie: " + speechToken);
    const idx = speechToken.indexOf(":");
    return {
      authToken: speechToken.slice(idx + 1),
      region: speechToken.slice(0, idx),
    };
  }
}
