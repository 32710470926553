import React from "react";
import "./Welcome.css";
function Welcome(props) {
  return (
    <div class="p-5 mb-4 bg-light rounded-3 welcome">
      <div class="container-fluid py-3">
        <h1 class="display-5 fw-bolder">Hello, {props.data.fName}!</h1>
        <p class="col-md-8 fs-4">{props.data.message}</p>
      </div>
    </div>

    // <div class="jumbotron bg-white welcome">
    //   <h1 class="display-4">Hello, {props.data.fName}!</h1>
    //   <p class="lead">{props.data.message}</p>
    //   {/* <hr class="my-4" />
    //   <p>
    //     It uses utility classes for typography and spacing to space content out within the larger
    //     container.
    //   </p>
    //   <p class="lead">
    //     <a class="btn btn-primary btn-lg" href="#" role="button">
    //       Learn more
    //     </a>
    //   </p> */}
    // </div>
  );
}

export default Welcome;
