import React from "react";
import Scenario from "../Scenario/Scenario";
import "./ScenarioList.css";
function ScenarioList(props) {
  return (
    <div className="flex-column scenario-list-div">
      {props.data.map((scenario) => {
        return (
          <Scenario
            title={scenario.title}
            id={scenario.id}
            code={scenario.code}
            img={scenario.img}
          ></Scenario>
        );
      })}
    </div>
  );
}

export default ScenarioList;
