import Sidebar from "../Sidebar/Sidebar";
import ScenarioList from "../ScenarioList/ScenarioList";
import Welcome from "../Welcome/Welcome";
import "./Practice.css";

export const testScenarioData = [
  {
    title: "Handle credit card decline",
    code: "support",
    detail: "You are handling credit card decline.",
    id: 0,
    img: "credit card.jpeg",
    checkList: ["Greeting and Identification",
      "Understanding the Issue",
      "Verifying Customer's Identity",
      "Reviewing Account Information",
      "Explaining Reasons for Decline",
      "Resolving Disputes or Errors",
      "Confirming Resolution",
      "Closing the Call",]
  },
  {
    title: "Ordering coffee at Starbucks",
    code: "library",
    detail: "You are ordering coffee at starbucks",
    id: 1,
    img: "book.jpeg",
    checkList: ["Greeting and Acknowledgmen",
    "Active Listening and Empathy",
    "Apologize and Validate Concerns",
    "Provide Clear and Concise Information",
    "Offer a Solution or Course of Action",
    "Implement the Solution or Next Steps",
    "Express Gratitude for Their Patience",
    "Closing the Conversation"]

  },
  {
    title: "Disatisfied Customer",
    code: "mcdonalds",
    detail: "You are handling an Disatisfied Customer.",
    id: 2,
    img: "burger.jpeg",
    checkList:["Greeting and Acknowledgment",
    "Apologizing for Their Dissatisfaction",
    "Encouraging Them to Share Their Concerns",
    "Actively Listening and Validating Their Feelings",
    "Gathering Relevant Information about the Issue",
    "Providing Ongoing Support and Updates",
    "Seeking Confirmation of Their Satisfaction",
    "Their Feedback and Close",]
  },
  {
    title: "Angry Customer",
    code: "costco",
    detail: "You are handling an Angry Customer.",
    id: 3,
    img: "service.jpeg",
    checkList:["Greeting and Empathy",
    "Allowing Them to Vent and Express Frustration",
    "Apologizing for Their Experience",
    "Assuring Them of Immediate Action",
    "Listening Carefully to Understand the Issue",
    "Offering a Solution or Compensation",
    "Explaining the Steps to Resolve the Problem",
    "Thanking Them for Their Feedback ",]
  },
  {
    title: "Frugal Customer",
    code: "starbucks",
    detail: "You are handling an Frugal Customer.",
    id: 4,
    img: "coffee.jpg",
    checkList:["Greeting and Acknowledgment",
    "Understanding Their Budget Constraints",
    "Exploring Affordable Options",
    "Offering Discounts or Promotions",
    "Providing Comparison Information",
    "Suggesting Cost-Saving Alternatives",
    "Offering Bundle or Package Deals",
    "Providing Clear Value Propositions",
    "Closing the Conversation",]
  },
];
function Practice() {
  const testUserData = {
    fName: "Abhinav",
    lName: "Garg",
    message: "It's good to see you again.",
    currentScenario: "Coffee at Starbucks",
    currentCompletion: 80,
  };

  const scenario_data = {
    starbucks: "You are handling an Frugal Customer.",
    library: "You are handling an Impatient Customer.",
    mcdonalds: "You are handling an Disatisfied Customer.",
    costco: "You are handling an Angry Customer.",
    support: "You are Handling credit card decline.",
  };

  return (
    <div>
      <div className="practice container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <Sidebar />
          </div>
          <div className="col-sm px-5 pt-5 pb-4 practice-col-2">
            <Welcome data={testUserData}> </Welcome>
            <h2>Scenarios</h2>
            <ScenarioList data={testScenarioData}></ScenarioList>
          </div>
          <div className="col-sm graph"></div>
        </div>
      </div>
    </div>
  );
}

export default Practice;
